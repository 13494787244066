<template>
  <v-dialog
    value="true"
    persistent
    max-width="35%"
    >
    <v-card>
      <v-toolbar color="primary" dark>
        <v-toolbar-title primary-title>Создание сотрудника</v-toolbar-title>
      </v-toolbar>
      <v-card-text>
        <v-form>
          <v-row>
            <v-col cols="12">
              <v-select
                label="Должность"
                :value="position"
                :items="$store.state.orgPositionsNames"
                item-text="name"
                item-value="id"
                @input="positionId = $event"
              />
            </v-col>
            <v-col cols="12">
              <v-select
                label="Роль"
                :value="role"
                :items="$store.state.rolesNames"
                item-text="name"
                item-value="id"
                @input="roleId = $event"
              />
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-btn color="default" @click="$emit('close')">Отмена</v-btn>
        <v-spacer></v-spacer>
        <v-btn color="success" @click="save">Сохранить</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>

export default {
  props: ['id', 'position', 'role'],
  data() { return {
    positionId: this.position.id,
    roleId: this.role.id,
  }},
  methods: {
    save() {
      this.$axios.post('organization/staff/update', {
        positionId: this.positionId,
        roleId: this.roleId,
      }, {
        params: { id: this.id }
      }).then(r => {
        this.$emit('input')
        this.$emit('close')
      })
    },
  },
}
</script>
