<template>
  <v-card width="60%" style="margin: 15px auto;">
    <v-toolbar color="primary" dark>
      <v-toolbar-title>Сотрудники</v-toolbar-title>
    </v-toolbar>
    <UpdateDialog
      v-if="updateDialogShow"
      :id="updateDialogId"
      :role="updateDialogRole"
      :position="updateDialogPosition"
      @close="updateDialogShow = false"
      @input="getStaff()"
    />
    <v-card-text>
      <v-row align="baseline">
        <v-col cols="8">
          <v-text-field
            label="Поиск по ФИО..."
            v-model="filter"
            append-icon="mdi-magnify"
            hide-details
          />
        </v-col>
        <v-col cols="4">
          <v-select
            :items="statusItems"
            v-model="status"
            label="Статус"
            @input="getStaff()"
          />
        </v-col>
      </v-row>
      <v-simple-table>
        <thead>
          <tr>
            <th style="width: 50px;"></th>
            <th class="text-left">ФИО</th>
            <th class="text-left">Должность</th>
            <th class="text-left">Роль</th>
            <!-- <th class="text-left">Статус</th> -->
            <th v-if="isAdmin"></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="s in staff">
            <td>
              <img v-if="s.thumbnail" :src="s.thumbnail" width="50px"/>
              <v-icon v-else size="50px">mdi-account</v-icon>
            </td>
            <td>{{getFio(s)}}</td>
            <td>{{positionName(s.positionId)}}</td>
            <td>{{roleName(s.roleId)}}</td>
            <!-- <td>{{getStatusById(u.status)}}</td> -->
            <td v-if="isAdmin">
              <div style="display: flex; align-items: center; justify-content: center;">
                <v-btn
                  v-if="s.status == 3"
                  icon
                  @click="acceptStaff(s.id)"
                  >
                  <v-icon>mdi-check</v-icon>
                </v-btn>
                <v-btn
                  v-if="s.status == 1"
                  icon
                  @click="updateStaff(s.id, s.positionId, s.roleId)"
                  >
                  <v-icon>mdi-pencil</v-icon>
                </v-btn>
                <v-btn
                  v-if="s.status == 1"
                  icon
                  @click="deleteStaff(s.id)"
                  >
                  <v-icon>mdi-delete</v-icon>
                </v-btn>
                <v-btn
                  v-if="s.status == 2"
                  icon
                  @click="restoreStaff(s.id)"
                  >
                  <v-icon>mdi-backup-restore</v-icon>
                </v-btn>
              </div>
            </td>
          </tr>
        </tbody>
      </v-simple-table>
    </v-card-text>
  </v-card>
</template>

<script>
import UpdateDialog from './components/UpdateDialog.vue'

export default {
  components: { UpdateDialog },
  data: () => ({
    staff: [],
    filter: '',
    searchRequestTimeout: null,
    updateDialogShow: false,
    updateDialogStaff: null,
    status: 1,
    statusItems: [
      { text: 'Активные', value: 1 },
      { text: 'Новые', value: 3 },
      { text: 'Удаленные', value: 2 },
    ]
  }),
  computed: {
    isAdmin() {
      return this.$store.getters['user/isAdmin']
    },
  },
  methods: {
    positionName(positionId) {
      let position = this.$store.state.orgPositionsNames.find(p => p.id == positionId)
      return position ? position.name : 'Не известно'
    },
    roleName(roleId) {
      let role = this.$store.state.rolesNames.find(r => r.id == roleId)
      return role ? role.name : 'Не известно'
    },
    acceptStaff(id) {
      this.$axios.post('organization/staff/accept', { id: id }).then(() => { this.getStaff() })
    },
    updateStaff(id, roleId, positionId, status) {
      let role = { id: Number(roleId), name: this.roleName(roleId) }
      let position = { id: Number(positionId), name: this.positionName(positionId) }
      this.updateDialogId = id
      this.updateDialogPosition = position
      this.updateDialogRole = role
      this.updateDialogShow = true
    },
    deleteStaff(id) {
      if (confirm('Действительно убрать сотрудника?')) {
        this.$axios.post('organization/staff/delete', { id: id }).then(r => {
          // this.$store.dispatch('setAlert', { message: r.data.message, type: 'info' })
          this.getStaff()
        })
      }
    },
    restoreStaff(id) {
      this.$axios.post('organization/staff/restore', { id: id }).then(() => { this.getStaff() })
    },
    getFio(u) {
      return `${u.lastname} ${u.firstname} ${u.middlename}`
    },
    getStaff() {
      this.$axios.get('organization/staff/index', { params: {
        filter: this.filter,
        status: this.status
      }}).then(r => {
        this.staff = r.data.items.slice()
      })
    }
  },
  watch: {
    filter(value) {
      if(value && value.length >= 2) {
        clearTimeout(this.searchRequestTimeout);
        this.searchRequestTimeout = setTimeout(() => this.getStaff(), 1000);
      }
    }
  },
  mounted() {
    this.getStaff()
  }
}
</script>
